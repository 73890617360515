import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { Banner } from 'components';
import { BASE_URL } from 'config';

const SafetyAndSupport = () => (
  <Layout page="safety-and-support">

    <Helmet
      title="Haka Tours Safety And Support"
      meta={[
        {
          name: 'description', content: 'The health and safety of our Haka guests is paramount, so we are committed to delivering exceptional experiences by endeavouring to meet, and exceed local and global industry safety protocols.',
        },
        { name: 'og:title', content: 'Haka Tours Safety And Support' },
        {
          name: 'og:description', content: 'The health and safety of our Haka guests is paramount, so we are committed to delivering exceptional experiences by endeavouring to meet, and exceed local and global industry safety protocols.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/safety-and-support/` }]}
    />

    <Banner backgroundImage="https://static-hakatours.imgix.net/HakaToursSafetyAndSupport.jpg?fit=crop&auto=format" />
    <section className="l-section">
      <div className="l-container">
        <h1 className="l-contact-us__title c-heading c-heading--h1 u-text--center">
          Safety & Support
        </h1>
        <h3 className="l-contact-us__title c-heading c-heading--h3 u-text--center">
          We have you covered
        </h3>
        <br />
        <p>
          The health and safety of our Haka guests is paramount, so we are committed to delivering exceptional experiences by endeavouring to meet, and exceed local and global industry safety protocols.
        </p>
        <p>
          We also appreciate that extra considerations are involved during this COVID-19 era,
          and you can be rest assured that we are following advice from <a href="https://www.who.int/">World Health Organisation (WHO)</a> and&nbsp;
          <a href="https://covid19.govt.nz/">New Zealand Covid 19</a> and rigorously applying the necessary steps for each of our tours.
          You can read more about this on our <a href="https://hakatours.com/travel-with-confidence/">Travel with Confidence page</a>.
        </p>
        <p>
          But don’t just take our word for it, we have received a variety of highly regarded accreditations, in addition to our own regular internal and external audits, to ensure we have your back.
        </p>
        <br />
        <br />
        <section className="l-alternating-panels">
          <div className="l-container--small" style={{ margin: 'auto' }}>
            <div className="l-logo-panels__item" key="Qualmark Accreditation">
              <div className="l-logo-panels__item-image">
                <img src="https://static-hakatours.imgix.net/QualmarkGoldAwardLogoStacked.jpg?w=200&fit=crop&auto=format" alt="Qualmark Accreditation" width="300" height="300" />
              </div>
              <div className="l-logo-panels__item-content">
                <h4 className="l-logo-panels__item-title c-heading c-heading--h4">Qualmark Accreditation</h4>
                <div>
                  <p>
                    Qualmark is New Zealand’s official quality assurance organisation that ensures operators provide a quality and safe experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="l-logo-panels__item" key="Covid Clean Approved">
              <div className="l-logo-panels__item-image">
                <img src="https://static-hakatours.imgix.net/CovidCleanApproved.jpeg?auto=format" alt="Qualmark Accreditation" width="300" height="300" />
              </div>
              <div className="l-logo-panels__item-content">
                <h4 className="l-logo-panels__item-title c-heading c-heading--h4">Covid Clean Approved</h4>
                <div>
                  <p>
                    Qualmark has certified that we have successfully completed the Covid Clean Approved assessment.
                  </p>
                </div>
              </div>
            </div>
            <div className="l-logo-panels__item" key="TEC NZ Covid Ready Approved">
              <div className="l-logo-panels__item-image">
                <img src="https://static-hakatours.imgix.net/TECNZTourismCOVIDReadyNZbadge.jpeg?w=200&fit=crop&auto=format" alt="Qualmark Accreditation" width="300" height="300" />
              </div>
              <div className="l-logo-panels__item-content">
                <h4 className="l-logo-panels__item-title c-heading c-heading--h4">TEC NZ Covid Ready Approved</h4>
                <div>
                  <p>
                    The Tourism Export Council NZ has approved that our COVID-19 Health and Safety plan aligns with the NZ Government approved COVID-19 guidelines & regulations.
                  </p>
                </div>
              </div>
            </div>
            <div className="l-logo-panels__item" key="Partnerships">
              <div className="l-logo-panels__item-image">
                <img src="/icons/hand-shake.png" alt="Partnerships" width="300" height="300" />
              </div>
              <div className="l-logo-panels__item-content">
                <h4 className="l-logo-panels__item-title c-heading c-heading--h4">Partnerships</h4>
                <div>
                  <p>
                    We carefully select the suppliers and partners we work with - such as activity and accommodation providers - to ensure that they align with our mission, values, and of course our health and safety procedures.
                    We also audit suppliers to ensure they have the appropriate health and safety measures in place.
                  </p>
                </div>
              </div>
            </div>
            <div className="l-logo-panels__item" key="Backing of Haka Tourism Group">
              <div className="l-logo-panels__item-image">
                <img src="/icons/group.png" alt="Backing of Haka Tourism Group" width="300" height="300" />
              </div>
              <div className="l-logo-panels__item-content">
                <h4 className="l-logo-panels__item-title c-heading c-heading--h4">Backing of Haka Tourism Group</h4>
                <div>
                  <p>
                    Being part of <a href="https://hakatourismgroup.com/">Haka Tourism Group</a> gives us the backing of New Zealand’s most highly awarded tourism company.
                    Our safety standards are extended across the network of national Haka accommodation, vehicle fleet and our awesome Haka guides & road crew!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </Layout>
);

export default SafetyAndSupport;
